import { ADD_MENU } from "./type";

const initialState = [


    {
        name: "The Caesar",
        description: "Crisp romaine lettuce tossed with our homemade Caesar dressing, croutons, and shredded parmesan cheese.",
        price: "19"
    },
    {
        name: "Surf & Turf",
        description: "A grilled, queen-cut ribeye served with shrimp and scallop alfredo.",
        price: "17"
    },
    {
        name: "Creamy Sage",
        description: "Chicken breast sautéed with fresh sage and prosciutto. Served atop creamy asiago linguini.",
        price: "21"
    },
    {
        name: "From the Sea",
        description: "Fresh haddock, gulf shrimp, and sea scallops dipped in beer batter and fried to a golden brown.",
        price: "23"
    },
];

const reducerCalculator = (state = initialState, action) => {
    switch (action.type) {
        case ADD_MENU:
            const { item } = action.payload;
            return {
                ...state,
                item,
            };

        default:
            return state;
    }
}

export default reducerCalculator;