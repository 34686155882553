import React, { useState } from 'react'
import our_0 from '../../images/our-services/0.jpg'
import our_1 from '../../images/our-services/1.jpg'
import our_1_1 from '../../images/our-services/1_1.jpg'
import our_2 from '../../images/our-services/2.jpg'
import our_3 from '../../images/our-services/3.jpg'
import our_4 from '../../images/our-services/4.jpg'
import { Modal, Timeline } from 'antd'
import how_is_work_1 from '../../images/how-is-work/1.jpg'
import how_is_work_2 from '../../images/how-is-work/2.jpg'
import how_is_work_3 from '../../images/how-is-work/3.jpg'
import how_is_work_4 from '../../images/how-is-work/4.jpg'
import TopSlider from '../../components/TopSlider'
import ContactForm from '../../components/ContactForm'

const Index = () => {
  const [isModalVisible, setIsModalVisible] = useState(false)

  const showModal = () => {
    setIsModalVisible(true)
  }
  return (
    <>
      <section className='section-1'>
        <div className='top-content'>
          <img className='u-image u-image-default u-image-1' src='images/logo.png' alt='' />
          <h1 className='u-custom-font u-text u-text-custom-color-1 u-text-1'>
            Tasty and beautiful <br />
            is our passion
          </h1>
        </div>
      </section>
      <section className='our-services'>
        <div className='our-services_title_wrap'>
          {/* <h2>Our services</h2> */}
          <p>Our main task is to organize your holiday at the highest level.</p>
          <p>
            We will make every effort to ensure that this special event, which you entrust to
            organize, is exactly the way you imagine it to be. For this reason, we have an
            individual approach to each holiday.
          </p>
          <p>
            The greatest reward for us is the satisfaction of your desires and the delight of your
            guests.
          </p>
        </div>

        <div className='our-services__wrap'>
          <div className='items'>
            <div className='item'>
              <img
                src={our_1}
                alt=''
                onMouseOver={(e) => (e.currentTarget.src = our_1_1)}
                onMouseOut={(e) => (e.currentTarget.src = our_1)}
              />
              <h3>Corporate services</h3>
              <p>
                If you want to achieve an even higher result in the activities of your company,
                enterprise or institution, then one of the secrets of this is a joint binge)
              </p>
              {/*<button onClick={showModal}>to order</button>*/}
            </div>
            <div className='item'>
              <img src={our_2} alt='' />
              <h3>Outside service</h3>
              <p>
                Exclusive cakes, divine pastries, delicious sweets, unforgettable desserts and
                delicious pies for every taste and holiday.
              </p>
              {/*<button onClick={showModal}>to order</button>*/}
            </div>
            {/*</div>*/}
            {/*<div className='items'>*/}
            <div className='item'>
              <img src={our_3} alt='' />
              <h3>Наш зал</h3>
              <p>
                Very often we want to change what we are used to. At least for a while. UNICUS can
                do it.
              </p>
              {/*<button onClick={showModal}>to order</button>*/}
            </div>
            <div className='item'>
              <img src={our_4} alt='' />
              <h3>Privite events</h3>
              <p>
                Agree that events such as weddings, birthdays, social events, graduations,
                celebrations related to your professional activity should be bright.
              </p>
              {/*<button onClick={showModal}>to order</button>*/}
            </div>
          </div>
        </div>
      </section>

      <section className='what_is_work'>
        <h2>How it works</h2>
        <Timeline>
          <Timeline.Item color='green' style={{ display: 'flex' }}>
            <div className='timeline-text'>
              <p>You have an event planned,</p>
              <p className='timeline-text__phone'>
                call: <a href='tel:+32 484 74 38 20'>+32 484 74 38 20</a>
              </p>
            </div>
            <img src={how_is_work_1} alt='' />
          </Timeline.Item>
          <Timeline.Item color='green' style={{ display: 'flex' }}>
            <div className='timeline-text'>
              <p>
                Or leave a request on our website, indicate the upcoming event, and we will call you
                back.
              </p>
              {/*<button*/}
              {/*    onClick={showModal}*/}
              {/*    style={{ color: 'black', marginTop: 20, cursor: 'pointer' }}>*/}
              {/*    to order*/}
              {/*</button>*/}
            </div>
            <img src={how_is_work_2} alt='' />
          </Timeline.Item>
          <Timeline.Item color='green' style={{ display: 'flex' }}>
            <div className='timeline-text'>
              <p>An appointment is made to agree on the menu.</p>
            </div>
            <img src={how_is_work_3} alt='' />
          </Timeline.Item>

          <Timeline.Item color='green' style={{ display: 'flex' }}>
            <div className='timeline-text'>
              <p>
                We come, cook, serve, decorate the table and the room for you and leave a lot of
                positive emotions.
              </p>
            </div>
            <img src={how_is_work_4} alt='' />
          </Timeline.Item>
        </Timeline>
      </section>

      {/*<section className='our_chefs'>*/}
      {/*    <h2>Our kitchen</h2>*/}
      {/*    <TopSlider />*/}
      {/*</section>*/}

      <section className='contact'>
        <h2>Our contacts</h2>
        <ContactForm />
        <div className='contact_content'>
          <div className='contact_block'>
            <div className='contact_phone'>
              <span>phone:</span>
              <a href='tel:+32 485 65 92 29'>+32 485 65 92 29</a>
            </div>
            <div className='contact_email'>
              <span>email:</span>
              <a href='mailto:info@unicus-catering.com'>info@unicus-catering.com</a>
            </div>
          </div>

          <div className='contact_address'>
            <p>address:</p>
            <div>
              <span>Oudebaan 106,</span>
              <br />
              <span>3360 Bierbeek</span>
              <br />
              <span>Belgique</span>
              <br />
            </div>
          </div>
        </div>
      </section>

      <footer className='home-footer'>
        {' '}
        ©2022 Created by{' '}
        <a target='_blank' href='https://devcolibri.biz'>
          devColibri.biz
        </a>
      </footer>
    </>
  )
}

export default Index
