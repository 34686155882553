import React from 'react';
import {Route, Switch} from 'react-router-dom';
import Home from "./pages/home";
import { Provider } from "./Context";
import 'antd/dist/antd.css';

const App = () => {

    return (
        <Provider>
            <Switch>
                <Route exact path="/">
                    <Home/>
                </Route>
                <Route path="*" component={Home} />
            </Switch>
        </Provider>
    );
};

export default App;