import { createStore, combineReducers } from 'redux';
import {composeWithDevTools} from "redux-devtools-extension"
import reducerTheme from "./theme/reducerTheme";
import reducerCalculator from "./calcculator/reducerCalculator";

const rootReducer = combineReducers({
    theme: reducerTheme,
    calculator: reducerCalculator,
});
const store = createStore(rootReducer, composeWithDevTools())

export default store;